<template>
  <div class="container p-2">
    <div class="bg-white radius-5 p-2 px-4 my-2" v-for="(list,index) in data" :key="index">
      <div class="row border-bottom">
        <div class="col-1 p-0">
          <img :src="list.company.logo" width="30" height="30" />
        </div>

        <div class="col-8 p-0 px-1 pt-1 text-truncate align-middle">
          {{ list.name }}
        </div>

        <div class="col-3 p-0 text-r pr-1 pt-1">
          <a class="text-muted" :href="`/tool/applys/info?job_id=${list.id}`">查看明细</a>
        </div>
      </div>

      <div class="row fs-9 py-1">
        <div class="col p-0">
          我的供应/已招: <a style="color: red">{{ list.apply_for_job }}</a> / {{ list.people_number }}人
        </div>
        <div class="col-4 text-r p-0">
          <div class="fa-solid fa-qrcode" @click="genCode(list.id)">
            <a id="qrcode" class="mx-1">报名二维码</a>
          </div> 
        </div>
      </div>
      
    </div>

  </div>
  <!-- qrcode -->
  <div v-show="showCode" class="wrap" @click="showCode = false">
        <img :src="qcode" />
  </div>
 
</template>
<script setup>
import { ref } from 'vue'
import QRCode from 'qrcode'

const data = ref([])

//测试数据
const a = 5
for(var i=0; i<a; i++) {
  data.value.push(
    {
      id: 100 + i,
      name: '人事主管',
      apply_for_job: 588, //应聘人数
      people_number: 1000, //招聘人数
      company: {
        id: 1001,
        name: '97打工网',
        logo: 'https://picsum.photos/40/40' //企业logo
      }
      
    }
  )
}

//报名二维码
  const showCode = ref(false)
  const ready = ref(false)
  const qcode = ref('')
  function genCode () {
      if (ready.value) {
          showCode.value = true
          return
      }

      QRCode.toDataURL('https://121.121cc.cn/attachment/lshd_zhaopinhign/1631347189.jpghttps://121.121cc.cn/attachment/lshd_zhaopinhign/1631347189.jpg', (err, url) => {
          if (err) throw err
          qcode.value = url
          ready.value = true
          showCode.value = true
      })
  }
//报名二维码 end

</script>

<style>
#qrcode{
    cursor: pointer;
    /* margin-top:40px;
    border:1px solid #17a2b8;
    background:#00796B;
    height:28px;
    border-radius:25px;
    line-height:1.8em;
    font-size:0.9rem;
    color:#ffffff;
    width: 80px;
    text-align: center; */
    }

.wrap {
    z-index: 99999;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
}

.wrap img {
    margin-top: 5em;
    width: 200px;
}
</style>